<template>
  <CChart
    v-if="PX && Object.keys(PX).length > 0"
    type="bar"
    :data="{
      labels: PX?.labels,
      datasets: [
        {
          label: 'PX Win',
          backgroundColor: hexToRgba(getStyle('--cui-primary'), 70),
          pointHoverBackgroundColor: getStyle('--cui-success'),
          borderWidth: 2,
          data: PX?.datasets[0],
          fill: true,
        },
        {
          label: 'PX Lose',
          backgroundColor:  hexToRgba(getStyle('--cui-danger'), 60),
          borderColor: getStyle('--cui-danger'),
          pointHoverBackgroundColor: getStyle('--cui-danger'),
          borderWidth: 2,
          borderDash: [8, 5],
          data: PX?.datasets[1],
        },

        {
          label: 'CX Win',
          backgroundColor: hexToRgba(getStyle('--cui-success'), 70),
          borderColor: getStyle('--cui-success'),
          pointHoverBackgroundColor: getStyle('--cui-success'),
          borderWidth: 2,
          data: CX?.datasets[0],
          fill: true,
        },
        {
          label: 'CX Lose',
          backgroundColor:  hexToRgba(getStyle('--cui-danger'), 60),
          borderColor: getStyle('--cui-danger'),
          pointHoverBackgroundColor: getStyle('--cui-danger'),
          borderWidth: 2,
          borderDash: [8, 5],
          data: CX?.datasets[1],
        },

        {
          label: 'FX Win',
          backgroundColor: hexToRgba(getStyle('--cui-info'), 60),
          borderColor: getStyle('--cui-success'),
          pointHoverBackgroundColor: getStyle('--cui-success'),
          borderWidth: 2,
          data: FX?.datasets[0],
          fill: true,
        },
        {
          label: 'FX Lose',
          backgroundColor: hexToRgba(getStyle('--cui-danger'), 60),
          borderColor: getStyle('--cui-danger'),
          pointHoverBackgroundColor: getStyle('--cui-danger'),
          borderWidth: 2,
          borderDash: [8, 5],
          data: FX?.datasets[1],
        }
      ],
    }"
    :options="options"
  />
</template>

<script>
import { CChart } from '@coreui/vue-chartjs';
import { computed } from 'vue-demi';
import { useStore } from 'vuex';
import { processOnlyDataPFC } from '../../plugins/tools';
import { getStyle, hexToRgba } from '@coreui/utils/src';
export default {
  name: 'ChartFiltersByDate',
  components: {
    CChart,
  },
  setup() {
    const store = useStore();

    const PX = computed(() => {
      const r = store.state.risbydate;
      return processOnlyDataPFC(r, 'month', 'PX');
    });

    const CX = computed(() => {
      const r = store.state.risbydate;
      return processOnlyDataPFC(r, 'month', 'CX');
    });

    const FX = computed(() => {
      const r = store.state.risbydate;
      return processOnlyDataPFC(r, 'month', 'FX');
    });

    const options = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            stepSize: Math.ceil(250 / 5),
            max: 250,
          },
        },
      },
      elements: {
        line: {
          tension: 0.4,
        },
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    }
    return {
      options,
      PX,
      FX,
      CX,
      getStyle,
      hexToRgba
    }
  }
}
</script>
