<template>
  <CRow>
    <CCol :md="3">
      <CWidgetStatsA class="mb-4" color="primary">
      <template #title>Numero segnali filtri P/F/C</template>
        <template #value>
          {{numSegnaliPFC}}
        </template>
      </CWidgetStatsA>
    </CCol>
    <CCol :md="3">
      <CWidgetStatsA class="mb-4" color="danger">
        <template #value>
        {{resultsAll.pareggi}}
        </template>
        <template #title>Pareggi</template>
      </CWidgetStatsA>
      <CWidgetStatsA class="mb-4" color="success">
        <template #value>
        {{resultsAll.spareggi}}
        </template>
        <template #title>Spareggi</template>
      </CWidgetStatsA>
    </CCol>
    <CCol :md="3">
      <CWidgetStatsA class="mb-4" color="danger">
        <template #value>
        {{resultsAll.pareggi_ht}}
        </template>
        <template #title>Pareggi HT</template>
      </CWidgetStatsA>
      <CWidgetStatsA class="mb-4" color="success">
        <template #value>
        {{resultsAll.spareggi_ht}}
        </template>
        <template #title>Spareggi HT</template>
      </CWidgetStatsA>
    </CCol>
    <CCol>
      <CWidgetStatsA class="mb-4" color="danger">
        <template #value>
        {{resultsAll.under25}}
        </template>
        <template #title>Under 2.5 FT</template>
      </CWidgetStatsA>
      <CWidgetStatsA class="mb-4" color="success">
        <template #value>
        {{resultsAll.over25}}
        </template>
        <template #title>Over 2.5 FT</template>
      </CWidgetStatsA>
    </CCol>
  </CRow>
</template>

<script>
import { getData } from '../../plugins/fetch'
import { ref } from 'vue-demi'
export default {
  name: 'WidgetsStatsA',
  setup() {
    const numSegnaliPFC = ref(0);
    const resultsAll = ref({});
    getData("https://api.soccerstandings.online/getAllRis").then((results) => {
      resultsAll.value = results;
      numSegnaliPFC.value = results.partite_totali;
    });

    return {
      numSegnaliPFC,
      resultsAll
    }

  }
}
</script>
