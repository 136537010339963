<template>
  <CChart
    v-if="data && Object.keys(data).length > 0"
    type="bar"
    :data="{
      labels: data.labels,
      datasets: [
        {
          label: 'Win',
          backgroundColor: hexToRgba(getStyle('--cui-success'), 10),
          borderColor: getStyle('--cui-success'),
          pointHoverBackgroundColor: getStyle('--cui-success'),
          borderWidth: 2,
          data: data.datasets[0],
          fill: true,
        },
        {
          label: 'Lose',
          backgroundColor: 'transparent',
          borderColor: getStyle('--cui-danger'),
          pointHoverBackgroundColor: getStyle('--cui-danger'),
          borderWidth: 2,
          borderDash: [8, 5],
          data: data.datasets[1],
        }
      ],
    }"
    :options="options"
  />
</template>

<script>
import { computed } from 'vue-demi';
import { CChart } from '@coreui/vue-chartjs';
import { getStyle, hexToRgba } from '@coreui/utils/src';
import { useStore } from 'vuex';
import { processOnlyData } from '../../plugins/tools';

export default {
  name: 'ChartFiltersByDate',
  components: {
    CChart,
  },
  setup() {
    const store = useStore();
    const data = computed(() => {
      const r = store.state.risAVG;
      return processOnlyData(r, 'month');
    });
    const options = {
      maintainAspectRatio: false,
      redraw: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            stepSize: Math.ceil(250 / 5),
            max: 250,
          },
        },
      },
      elements: {
        line: {
          tension: 0.4,
        },
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    }
    return {
      data,
      options,
      getStyle,
      hexToRgba
    }
  }
}
</script>
