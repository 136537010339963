import { getStyle, hexToRgba } from '@coreui/utils/src';

export function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    let localeString = a.toLocaleString();
    let dateOnly = localeString.split(',')[0];
    let explodeDate = dateOnly.split('/');
    var year = explodeDate[2];
    var month = explodeDate[1];
    month = month.toString().length == 1 ? '0' + month : month;
    let date = explodeDate[0];
    date = date.toString().length == 1 ? '0' + date : date;
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return [time, date + '-' + month + '-' + year];
}

export function decomposeDate(stringDate, delimiter) {
  /*
      Date Format must be: day/month/year
      Example: 01/01/2022
  */
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const dates = {
    '01': 'Gennaio',
    '02': 'Febbraio',
    '03': 'Marzo',
    '04': 'Aprile',
    '05': 'Maggio',
    '06': 'Giugno',
    '07': 'Luglio',
    '08': 'Agosto',
    '09': 'Settembre',
    '10': 'Ottobre',
    '11': 'Novembre',
    '12': 'Dicembre'
  }
  const explodedDate = stringDate.split(delimiter);
  if (explodedDate.length > 0) {
    // if (explodedDate[2] != currentYear) return;
    const dEl = explodedDate[1];
    return dates[dEl];
  }
}

function getYear(stringDate, delimiter) {
  const explodedDate = stringDate.split(delimiter);
  if (explodedDate.length > 0) {
    return explodedDate[2];
  }
}

function reduceByMonth(reduced) {
  let risByMonth = {};

  for (const date in reduced) {
    if (Object.hasOwnProperty.call(reduced, date)) {
      const val = parseInt(reduced[date]);
      if (isNaN(risByMonth[decomposeDate(date, '/')])) {
        risByMonth[decomposeDate(date, '/')] = val;
      } else {
        risByMonth[decomposeDate(date, '/')] += val;
      }
    }
  }
  return risByMonth;
}

function reduceByYear(reduced) {
  let risByYear = {};

  for (const date in reduced) {
    if (Object.hasOwnProperty.call(reduced, date)) {
      const val = parseInt(reduced[date]);
      const year = getYear(date, '/');

      if (isNaN(risByYear[year])) {
        risByYear[year] = val;
      } else {
        risByYear[year] += val;
      }
    }
  }
  return risByYear;
}

export function processData(res, by) {
  let ris = {};
  if (res && res.length > 0) {
    for (const iterator of res) {
      try {
        ris[iterator.date].push(iterator);
      } catch (error) {
        ris[iterator.date] = [iterator];
      }
    }
    let reducedWin = {};
    let reducedLose = {};

    for (const key in ris) {
      if (Object.hasOwnProperty.call(ris, key)) {
        const g = ris[key];
        const f = g.filter(elWin => elWin.win_lose == true);
        const l = g.filter(elLose => elLose.win_lose == false);
        reducedWin[key] = f.length;
        reducedLose[key] = l.length;
      }
    }

    const labels = () => {
      if (by == 'date') {
        return Object.keys(ris);
      } else if (by == 'month') {
        return Object.keys(reduceByMonth(reducedWin));
      } else {
        return Object.keys(reduceByYear(reducedWin));
      }
    }

    const dataAVGWin = () => {
      if (by == 'date') return Object.values(reducedWin);
      if (by == 'month') return Object.values(reduceByMonth(reducedWin));
      return Object.values(reduceByYear(reducedWin));
    }
    const dataAVGLose = () => {
      if (by == 'date') return Object.values(reducedLose);
      if (by == 'month') return Object.values(reduceByMonth(reducedLose));
      return Object.values(reduceByYear(reducedLose));
    }
    return {
      labels: [...labels()],
      datasets: [
        {
          label: 'Win',
          backgroundColor: hexToRgba(getStyle('--cui-success'), 10),
          borderColor: getStyle('--cui-success'),
          pointHoverBackgroundColor: getStyle('--cui-success'),
          borderWidth: 2,
          data: dataAVGWin(),
          fill: true,
        },
        {
          label: 'Lose',
          backgroundColor: 'transparent',
          borderColor: getStyle('--cui-danger'),
          pointHoverBackgroundColor: getStyle('--cui-danger'),
          borderWidth: 2,
          borderDash: [8, 5],
          data: dataAVGLose(),
        }
      ],
    }
  }
}

export function processOnlyData(res, by) {
  let ris = {};
  if (res && res.length > 0) {
    for (const iterator of res) {
      try {
        ris[iterator.date].push(iterator);
      } catch (error) {
        ris[iterator.date] = [iterator];
      }
    }
    let reducedWin = {};
    let reducedLose = {};

    for (const key in ris) {
      if (Object.hasOwnProperty.call(ris, key)) {
        const g = ris[key];
        const f = g.filter(elWin => elWin.win_lose == true);
        const l = g.filter(elLose => elLose.win_lose == false);
        reducedWin[key] = f.length;
        reducedLose[key] = l.length;
      }
    }

    const labels = () => {
      if (by == 'date') {
        return Object.keys(ris);
      } else if (by == 'month') {
        return Object.keys(reduceByMonth(reducedWin));
      } else {
        return Object.keys(reduceByYear(reducedWin));
      }
    }

    const dataAVGWin = () => {
      if (by == 'date') return Object.values(reducedWin);
      if (by == 'month') return Object.values(reduceByMonth(reducedWin));
      return Object.values(reduceByYear(reducedWin));
    }
    const dataAVGLose = () => {
      if (by == 'date') return Object.values(reducedLose);
      if (by == 'month') return Object.values(reduceByMonth(reducedLose));
      return Object.values(reduceByYear(reducedLose));
    }
    return {
      labels: [...labels()],
      datasets: [dataAVGWin(), dataAVGLose()],
    }
  }
}


export function processOnlyDataPFC(res, by, filter_name) {
  let ris = {};
  if (res && res.length > 0) {
    for (const iterator of res.filter((el) => el.filter_name == filter_name)) {
      try {
        ris[iterator.date].push(iterator);
      } catch (error) {
        ris[iterator.date] = [iterator];
      }
    }
    let reducedWin = {};
    let reducedLose = {};

    for (const key in ris) {
      if (Object.hasOwnProperty.call(ris, key)) {
        const g = ris[key];
        const f = g.filter(elWin => elWin.win_lose == true);
        const l = g.filter(elLose => elLose.win_lose == false);
        reducedWin[key] = f.length;
        reducedLose[key] = l.length;
      }
    }

    const labels = () => {
      if (by == 'date') {
        return Object.keys(ris);
      } else if (by == 'month') {
        return Object.keys(reduceByMonth(reducedWin));
      } else {
        return Object.keys(reduceByYear(reducedWin));
      }
    }

    const dataWin = () => {
      if (by == 'date') return Object.values(reducedWin);
      if (by == 'month') return Object.values(reduceByMonth(reducedWin));
      return Object.values(reduceByYear(reducedWin));
    }
    const dataLose = () => {
      if (by == 'date') return Object.values(reducedLose);
      if (by == 'month') return Object.values(reduceByMonth(reducedLose));
      return Object.values(reduceByYear(reducedLose));
    }
    return {
      labels: [...labels()],
      datasets: [dataWin(), dataLose()],
    }
  }
}

