<template>
  <div>
    <WidgetsStatsA />
    <CRow>
      <CCol :md="12">
        <CCard class="mb-4">
          <CCardBody>
            <CRow>
              <CCol :sm="5">
                <h4 id="traffic" class="card-title mb-0">Andamento Filtro AVG</h4>
              </CCol>
            </CRow>
            <CRow>
              <ChartFilterbyDate
                style="height: 300px;min-width: 300px; max-height: 300px; margin-top: 40px"
              />
            </CRow>
            <CRow>
              <CCol :sm="6">
                <ChartFilterbyMonth
                  style="height: 300px; max-height: 300px; margin-top: 40px"
                />
              </CCol>
              <CCol :sm="6">
                <ChartFilterbyYear
                  style="height: 300px;min-width: 300px; max-width: 700px; max-height: 300px; margin-top: 40px"
                />
              </CCol>


            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol :md="12">
        <CCard class="mb-4">
          <CCardBody>
            <CRow>
              <CCol :sm="5">
                <h4 id="traffic" class="card-title mb-0">Andamento Filtro Px Cx Fx</h4>
              </CCol>
            </CRow>
            <CRow>
              <ChartFilterbyDatePX
                style="height: 300px;min-width: 300px; max-height: 300px; margin-top: 40px"
              />
            </CRow>
            <CRow>
              <CCol :sm="6">

              </CCol>
              <CCol :sm="6">

              </CCol>


            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ChartFilterbyDate from './charts/ChartFilterbyDate.vue'
import ChartFilterbyMonth from './charts/ChartFilterbyMonth.vue'
import ChartFilterbyYear from './charts/ChartFilterbyYear.vue'
import ChartFilterbyDatePX from './charts/ChartFilterbyDatePX.vue'
import WidgetsStatsA from './widgets/WidgetsStatsTypeA.vue'
import WidgetsStatsD from './widgets/WidgetsStatsTypeD.vue'
import WidgetsStatsTypeB from './widgets/WidgetsStatsTypeB.vue'
// import { watchEffect, ref } from 'vue-demi'
// import { processOnlyData } from '../plugins/tools';
import { useStore } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    WidgetsStatsA,
    WidgetsStatsD,
    WidgetsStatsTypeB,
    ChartFilterbyDate,
    ChartFilterbyMonth,
    ChartFilterbyYear,
    ChartFilterbyDatePX
  },
  data() {
    return {
      filterBy: 'date',
      dataAVG: [],
      dataAllDraw: [],
      urlAVG: 'https://api.soccerstandings.online/getRisDate/AVG',
      urlAllDraw: 'https://api.soccerstandings.online/getRisDate/AllDraw'
    }
  },
  setup() {
    // const dataAVG = ref({});
    // watchEffect(async () => {
    //   const response = await fetch('https://api.soccerstandings.online/getRisDate/AVG')
    //   const awaitedRes = await response.json();
    //   dataAVG.value = processOnlyData(awaitedRes, 'date');
    // })
    const store = useStore();
    store.dispatch('get_ris_by_date', 'AVG');
    store.dispatch('get_ris_by_date', 'AllDraw');
  }
}
</script>
